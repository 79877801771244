





























































import { SchoolClassResponse } from "@/api/school-class/types/Responses";
import { SchoolYearResponse } from "@/api/school-year/types/Responses";
import {
  CreateHolidayTimetableRecordRequest,
  CreatePossibilityToBringChildTimetableRecordRequest,
  CreatePossibilityToPickUpChildTimetableRecordRequest,
  UpdateHolidayTimetableRecordRequest,
  UpdatePossibilityToBringChildTimetableRecordRequest,
  UpdatePossibilityToPickUpChildTimetableRecordRequest,
} from "@/api/timetable/types/Requests";
import {
  AbstractTimetableRecordResponse, HolidayTimetableRecordResponse, PossibilityToBringChildTimetableRecordResponse,
  PossibilityToPickUpChildTimetableRecordResponse,
} from "@/api/timetable/types/Responses";
import HolidayTimetableRecordForm from "@/components/timetable/form/HolidayTimetableRecordForm.vue";
import PossibilityToBringChildTimetableRecordForm
  from "@/components/timetable/form/PossibilityToBringChildTimetableRecordForm.vue";
import PossibilityToPickUpChildTimetableRecordForm
  from "@/components/timetable/form/PossibilityToPickUpChildTimetableRecordForm.vue";
import Vue, { PropType } from "vue";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
  name: "TimetableRecordForm",
  components: {
    PossibilityToBringChildTimetableRecordForm,
    PossibilityToPickUpChildTimetableRecordForm,
    HolidayTimetableRecordForm,
  },
  props: {
    isBusySubmit: {
      type: Boolean,
      required: true,
    },
    isBusyDelete: {
      type: Boolean,
      default: () => false,
    },
    schoolYear: {
      type: null as unknown as PropType<SchoolYearResponse | null>,
      required: true,
    },
    schoolClass: {
      type: null as unknown as PropType<SchoolClassResponse | null>,
      required: true,
    },
    defaults: {
      type: Object as PropType<AbstractTimetableRecordResponse>,
      required: false,
    },
  },
  data: () => ({
    // tab
    tab: 0 as number,
  }),
  computed: {
    isBusy(): boolean {
      return this.isBusySubmit;
    },
    dialogTitle(): TranslateResult {
      return !this.defaults
        ? this.$t("timetables.create_timetable_record")
        : this.$t("timetables.edit_timetable_record");
    },
  },
  methods: {
    onSubmitPossibilityToBringChildTimetableRecord(request: CreatePossibilityToBringChildTimetableRecordRequest | UpdatePossibilityToBringChildTimetableRecordRequest): void {
      this.$emit("onSubmitPossibilityToBringChildTimetableRecord", request);
    },
    onSubmitPossibilityToPickUpChildTimetableRecord(request: CreatePossibilityToPickUpChildTimetableRecordRequest | UpdatePossibilityToPickUpChildTimetableRecordRequest): void {
      this.$emit("onSubmitPossibilityToPickUpChildTimetableRecord", request);
    },
    onSubmitHolidayTimetableRecord(request: CreateHolidayTimetableRecordRequest | UpdateHolidayTimetableRecordRequest): void {
      this.$emit("onSubmitHolidayTimetableRecord", request);
    },
    onClose(): void {
      this.$emit("onClose");
    },
    onDelete(): void {
      this.$emit("onDelete");
    },
    initTabs(): void {
      if (this.defaults) {
        if (this.defaults instanceof PossibilityToBringChildTimetableRecordResponse) this.tab = 0;
        if (this.defaults instanceof PossibilityToPickUpChildTimetableRecordResponse) this.tab = 1;
        if (this.defaults instanceof HolidayTimetableRecordResponse) this.tab = 2;
      }
    },
  },
  created(): void {
    this.initTabs();
  },
});
