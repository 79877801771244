





















































































































import { SchoolClassResponse } from "@/api/school-class/types/Responses";
import { SchoolYearResponse } from "@/api/school-year/types/Responses";
import {
  CreateHolidayTimetableRecordRequest,
  UpdateHolidayTimetableRecordRequest,
} from "@/api/timetable/types/Requests";
import { AbstractTimetableRecordResponse, HolidayTimetableRecordResponse } from "@/api/timetable/types/Responses";
import moment from "moment";
import Vue, { PropType } from "vue";
import { TranslateResult } from "vue-i18n";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default Vue.extend({
  name: "HolidayTimetableRecordForm",
  mixins: [validationMixin],
  props: {
    isBusySubmit: {
      type: Boolean,
      required: true,
    },
    isBusyDelete: {
      type: Boolean,
      default: () => false,
    },
    schoolYear: {
      type: null as unknown as PropType<SchoolYearResponse | null>,
      required: true,
    },
    schoolClass: {
      type: null as unknown as PropType<SchoolClassResponse | null>,
      required: true,
    },
    defaults: {
      type: null as unknown as PropType<AbstractTimetableRecordResponse | null>,
      required: false,
    },
  },
  data: () => ({
    // dialogs
    dateFromDialog: false,
    dateToDialog: false,
    // form data
    form: {
      date_from: "" as string,
      date_to: "" as string,
      message: null as string | null,
    },
  }),
  validations: {
    form: {
      date_from: { required },
      date_to: { required },
      message: {},
    },
  },
  computed: {
    isBusy(): boolean {
      return this.isBusySubmit || this.isBusyDelete;
    },
    dateFromFormatted(): string {
      return this.form.date_from ? moment(this.form.date_from).format("LL") : "";
    },
    dateToFormatted(): string {
      return this.form.date_to ? moment(this.form.date_to).format("LL") : "";
    },
    dateFromErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.date_from?.$dirty) return errors;
      !this.$v.form.date_from.required && errors.push(this.$t("timetables.validation.date_from.required"));
      return errors;
    },
    dateToErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.date_to?.$dirty) return errors;
      !this.$v.form.date_to.required && errors.push(this.$t("timetables.validation.date_to.required"));
      return errors;
    },
    messageErrors(): TranslateResult[] {
      return [];
    },
  },
  methods: {
    onSubmit(): void {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      if (this.defaults) {
        const request: UpdateHolidayTimetableRecordRequest = {
          date_from: this.form.date_from,
          date_to: this.form.date_to,
          message: this.form.message,
        };
        this.$emit("onSubmit", request);
      } else {
        const request: CreateHolidayTimetableRecordRequest = {
          date_from: this.form.date_from,
          date_to: this.form.date_to,
          message: this.form.message,
          school_year: this.schoolYear !== null ? this.schoolYear.id : null,
          school_class: this.schoolClass !== null ? this.schoolClass.id : null,
        };
        this.$emit("onSubmit", request);
      }
    },
    onClose(): void {
      this.$emit("onClose");
    },
    onDelete(): void {
      if (!confirm(this.$t("timetables.confirm_delete").toString())) {
        return;
      }
      this.$emit("onDelete");
    },
    initDefaults(): void {
      if (this.defaults && this.defaults instanceof HolidayTimetableRecordResponse) {
        this.form.date_from = this.defaults.date_from.format("YYYY-MM-DD");
        this.form.date_to = this.defaults.date_to.format("YYYY-MM-DD");
        this.form.message = this.defaults.message;
      }
    },
  },
  created(): void {
    if ((this.schoolYear === null && this.schoolClass === null) || (this.schoolYear !== null && this.schoolClass !== null)) {
      throw "Just one of properties schoolYear and schoolClass should not be null";
    }
    this.initDefaults();
  },
});
