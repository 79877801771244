















import { SchoolClassResponse } from "@/api/school-class/types/Responses";
import { SchoolYearResponse } from "@/api/school-year/types/Responses";
import TimetableRecordClient from "@/api/timetable/TimetableRecordClient";
import {
  UpdateHolidayTimetableRecordRequest,
  UpdatePossibilityToBringChildTimetableRecordRequest,
  UpdatePossibilityToPickUpChildTimetableRecordRequest,
  UpdateTimetableRecordRequest,
} from "@/api/timetable/types/Requests";
import { AbstractTimetableRecordResponse } from "@/api/timetable/types/Responses";
import TimetableRecordForm from "@/components/timetable/form/TimetableRecordForm.vue";
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "EditTimetableRecordForm",
  components: { TimetableRecordForm },
  props: {
    timetableRecord: {
      type: Object as PropType<AbstractTimetableRecordResponse>,
      required: true,
    },
    schoolYear: {
      type: null as unknown as PropType<SchoolYearResponse | null>,
      required: true,
    },
    schoolClass: {
      type: null as unknown as PropType<SchoolClassResponse | null>,
      required: true,
    },
  },
  data: () => ({
    // loading
    isBusySubmit: false,
    isBusyDelete: false,
  }),
  methods: {
    onSubmitPossibilityToBringChildTimetableRecord(request: UpdatePossibilityToBringChildTimetableRecordRequest): void {
      this.onSubmit({ possibility_to_bring_child_timetable_record: request });
    },
    onSubmitPossibilityToPickUpChildTimetableRecord(request: UpdatePossibilityToPickUpChildTimetableRecordRequest): void {
      this.onSubmit({ possibility_to_pick_up_child_timetable_record: request });
    },
    onSubmitHolidayTimetableRecord(request: UpdateHolidayTimetableRecordRequest): void {
      this.onSubmit({ holiday_timetable_record: request });
    },
    onSubmit(request: UpdateTimetableRecordRequest): void {
      this.isBusySubmit = true;
      TimetableRecordClient.updateTimetableRecord(this.timetableRecord.id, request)
        .then((response: AbstractTimetableRecordResponse) => {
          this.$snackbarSuccess(this.$t("timetables.timetable_record_updated"));
          this.$emit("onSubmit", response);
        })
        .finally(() => {
          this.isBusySubmit = false;
        });
    },
    onDelete(): void {
      this.isBusyDelete = true;
      TimetableRecordClient.deleteTimetableRecord(this.timetableRecord.id)
        .then(() => {
          this.$snackbarSuccess(this.$t("timetables.timetable_record_deleted"));
          this.$emit("onDelete");
        })
        .finally(() => {
          this.isBusyDelete = false;
        });
    },
    onClose(): void {
      this.$emit("onClose");
    },
  },
});
