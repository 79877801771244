















































































































































































































import { SchoolClassResponse } from "@/api/school-class/types/Responses";
import { SchoolYearResponse } from "@/api/school-year/types/Responses";
import {
  CreatePossibilityToPickUpChildTimetableRecordRequest,
  UpdatePossibilityToPickUpChildTimetableRecordRequest,
} from "@/api/timetable/types/Requests";
import {
  AbstractTimetableRecordResponse,
  PossibilityToPickUpChildTimetableRecordResponse,
} from "@/api/timetable/types/Responses";
import _ from "lodash";
import moment from "moment";
import Vue, { PropType } from "vue";
import { TranslateResult } from "vue-i18n";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default Vue.extend({
  name: "PossibilityToPickUpChildTimetableRecordForm",
  mixins: [validationMixin],
  props: {
    isBusySubmit: {
      type: Boolean,
      required: true,
    },
    isBusyDelete: {
      type: Boolean,
      default: () => false,
    },
    schoolYear: {
      type: null as unknown as PropType<SchoolYearResponse | null>,
      required: true,
    },
    schoolClass: {
      type: null as unknown as PropType<SchoolClassResponse | null>,
      required: true,
    },
    defaults: {
      type: null as unknown as PropType<AbstractTimetableRecordResponse | null>,
      required: false,
    },
  },
  data: () => ({
    // dialogs
    dateFromDialog: false,
    dateToDialog: false,
    timeFromDialog: false,
    timeToDialog: false,
    // form data
    form: {
      date_from: "" as string,
      date_to: "" as string,
      time_from: "" as string,
      time_to: "" as string,
      weekdays: [] as number[],
      message: null as string | null,
    },
  }),
  validations: {
    form: {
      date_from: { required },
      date_to: { required },
      time_from: { required },
      time_to: { required },
      weekdays: { required },
      message: {},
    },
  },
  computed: {
    isBusy(): boolean {
      return this.isBusySubmit || this.isBusyDelete;
    },
    weekdays(): TranslateResult[] {
      return ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]
        .map((weekday: string): TranslateResult => this.$t("timetables." + weekday));
    },
    dateFromFormatted(): string {
      return this.form.date_from ? moment(this.form.date_from).format("LL") : "";
    },
    dateToFormatted(): string {
      return this.form.date_to ? moment(this.form.date_to).format("LL") : "";
    },
    dateFromErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.date_from?.$dirty) return errors;
      !this.$v.form.date_from.required && errors.push(this.$t("timetables.validation.date_from.required"));
      return errors;
    },
    dateToErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.date_to?.$dirty) return errors;
      !this.$v.form.date_to.required && errors.push(this.$t("timetables.validation.date_to.required"));
      return errors;
    },
    timeFromErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.time_from?.$dirty) return errors;
      !this.$v.form.time_from.required && errors.push(this.$t("timetables.validation.time_from.required"));
      return errors;
    },
    timeToErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.time_to?.$dirty) return errors;
      !this.$v.form.time_to.required && errors.push(this.$t("timetables.validation.time_to.required"));
      return errors;
    },
    messageErrors(): TranslateResult[] {
      return [];
    },
  },
  methods: {
    onSubmit(): void {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      if (this.defaults) {
        const request: UpdatePossibilityToPickUpChildTimetableRecordRequest = {
          date_from: this.form.date_from,
          date_to: this.form.date_to,
          time_from: this.form.time_from + ":00",
          time_to: this.form.time_to + ":00",
          repeat_on_monday: this.form.weekdays.includes(0) ? "true" : "false",
          repeat_on_tuesday: this.form.weekdays.includes(1) ? "true" : "false",
          repeat_on_wednesday: this.form.weekdays.includes(2) ? "true" : "false",
          repeat_on_thursday: this.form.weekdays.includes(3) ? "true" : "false",
          repeat_on_friday: this.form.weekdays.includes(4) ? "true" : "false",
          repeat_on_saturday: this.form.weekdays.includes(5) ? "true" : "false",
          repeat_on_sunday: this.form.weekdays.includes(6) ? "true" : "false",
          message: this.form.message,
        };
        this.$emit("onSubmit", request);
      } else {
        const request: CreatePossibilityToPickUpChildTimetableRecordRequest = {
          date_from: this.form.date_from,
          date_to: this.form.date_to,
          time_from: this.form.time_from + ":00",
          time_to: this.form.time_to + ":00",
          repeat_on_monday: this.form.weekdays.includes(0) ? "true" : "false",
          repeat_on_tuesday: this.form.weekdays.includes(1) ? "true" : "false",
          repeat_on_wednesday: this.form.weekdays.includes(2) ? "true" : "false",
          repeat_on_thursday: this.form.weekdays.includes(3) ? "true" : "false",
          repeat_on_friday: this.form.weekdays.includes(4) ? "true" : "false",
          repeat_on_saturday: this.form.weekdays.includes(5) ? "true" : "false",
          repeat_on_sunday: this.form.weekdays.includes(6) ? "true" : "false",
          message: this.form.message,
          school_year: this.schoolYear !== null ? this.schoolYear.id : null,
          school_class: this.schoolClass !== null ? this.schoolClass.id : null,
        };
        this.$emit("onSubmit", request);
      }
    },
    onClose(): void {
      this.$emit("onClose");
    },
    onDelete(): void {
      if (!confirm(this.$t("timetables.confirm_delete").toString())) {
        return;
      }
      this.$emit("onDelete");
    },
    initDefaults(): void {
      if (this.defaults && this.defaults instanceof PossibilityToPickUpChildTimetableRecordResponse) {
        this.form.date_from = this.defaults.date_from.format("YYYY-MM-DD");
        this.form.date_to = this.defaults.date_to.format("YYYY-MM-DD");
        this.form.time_from = this.defaults.time_from.format("HH:mm");
        this.form.time_to = this.defaults.time_to.format("HH:mm");
        if (this.defaults.repeat_on_monday) this.form.weekdays.push(0);
        if (this.defaults.repeat_on_tuesday) this.form.weekdays.push(1);
        if (this.defaults.repeat_on_wednesday) this.form.weekdays.push(2);
        if (this.defaults.repeat_on_thursday) this.form.weekdays.push(3);
        if (this.defaults.repeat_on_friday) this.form.weekdays.push(4);
        if (this.defaults.repeat_on_saturday) this.form.weekdays.push(5);
        if (this.defaults.repeat_on_sunday) this.form.weekdays.push(6);
        this.form.message = this.defaults.message;
      }
    },
    allowedMinutes(minutes: string): boolean {
      return _.toInteger(minutes) % 5 === 0;
    },
  },
  created(): void {
    if ((this.schoolYear === null && this.schoolClass === null) || (this.schoolYear !== null && this.schoolClass !== null)) {
      throw "Just one of properties schoolYear and schoolClass should not be null";
    }
    this.initDefaults();
  },
});
