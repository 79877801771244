import AbstractClient, { ClientOptions, FilterQueryParameters } from "@/api/AbstractClient";
import { PaginatedResponse } from "@/api/common/types/Responses";
import { CreateTimetableRecordRequest, UpdateTimetableRecordRequest } from "@/api/timetable/types/Requests";
import {
  AbstractTimetableRecordResponse,
  HolidayTimetableRecordResponse, PossibilityToBringChildTimetableRecordResponse,
  PossibilityToPickUpChildTimetableRecordResponse,
} from "@/api/timetable/types/Responses";
import TimetableRecordType from "@/enums/TimetableRecordType";
import { AxiosResponse } from "axios";
import _ from "lodash";

export default class TimetableRecordClient extends AbstractClient {
  /**
   * Perform get timetable records by school year request
   * @param schoolYearId school year ID
   * @param pagination pagination/filters/sort configuration
   * @param options client options
   */
  static getTimetableRecordsBySchoolYear(schoolYearId: number, pagination: FilterQueryParameters, options: ClientOptions = {}): Promise<PaginatedResponse<AbstractTimetableRecordResponse>> {
    return AbstractClient.get("/api/timetable-records/by-school-year/" + schoolYearId, pagination, options)
      .then((response) => {
        const timetableRecords = _.toArray(response.data.items).map((data: any): AbstractTimetableRecordResponse => TimetableRecordClient.buildTimetableRecord(data));
        return PaginatedResponse.fromResponseDataItems(timetableRecords, response.data._pagination);
      });
  }

  /**
   * Perform get timetable records by school class request
   * @param schoolClassId school class ID
   * @param pagination pagination/filters/sort configuration
   * @param options client options
   */
  static getTimetableRecordsBySchoolClass(schoolClassId: number, pagination: FilterQueryParameters, options: ClientOptions = {}): Promise<PaginatedResponse<AbstractTimetableRecordResponse>> {
    return AbstractClient.get("/api/timetable-records/by-school-class/" + schoolClassId, pagination, options)
      .then((response) => {
        const timetableRecords = _.toArray(response.data.items).map((data: any): AbstractTimetableRecordResponse => TimetableRecordClient.buildTimetableRecord(data));
        return PaginatedResponse.fromResponseDataItems(timetableRecords, response.data._pagination);
      });
  }

  /**
   * Perform create timetable record request
   * @param request create timetable record request
   */
  static createTimetableRecord(request: CreateTimetableRecordRequest): Promise<AbstractTimetableRecordResponse> {
    return AbstractClient.post("/api/timetable-records", request)
      .then((response) => TimetableRecordClient.buildTimetableRecord(response.data));
  }

  /**
   * Perform update timetable record request
   * @param timetableRecordId timetable record ID
   * @param request update timetable record request
   */
  static updateTimetableRecord(timetableRecordId: number, request: UpdateTimetableRecordRequest): Promise<AbstractTimetableRecordResponse> {
    return AbstractClient.patch("/api/timetable-records/" + timetableRecordId, request)
      .then((response) => TimetableRecordClient.buildTimetableRecord(response.data));
  }

  /**
   * Perform delete timetable record request
   * @param timetableRecordId timetable record ID
   */
  static deleteTimetableRecord(timetableRecordId: number): Promise<AxiosResponse> {
    return AbstractClient.delete("/api/timetable-records/" + timetableRecordId);
  }

  /**
   * Build timetable record of specific type from response data
   * @param data response data
   * @private
   */
  private static buildTimetableRecord(data: any): AbstractTimetableRecordResponse {
    switch (data.type) {
      case TimetableRecordType.POSSIBILITY_TO_BRING_CHILD_TIMETABLE_RECORD:
        return new PossibilityToBringChildTimetableRecordResponse(data);
      case TimetableRecordType.POSSIBILITY_TO_PICK_UP_CHILD_TIMETABLE_RECORD:
        return new PossibilityToPickUpChildTimetableRecordResponse(data);
      case TimetableRecordType.HOLIDAY_TIMETABLE_RECORD:
        return new HolidayTimetableRecordResponse(data);
      default:
        throw "Unsupported timetable record type";
    }
  }
}
