var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('data-table',{ref:"table",attrs:{"client":_vm.client,"client-function":_vm.clientFunction,"client-function-parameters":_vm.clientFunctionParameters,"headers":_vm.headers,"actions":_vm.actions},scopedSlots:_vm._u([{key:"item.date_from",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.date_from.format("LL"))+" ")]}},{key:"item.date_to",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.date_to.format("LL"))+" ")]}},{key:"item.time_from",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.time_from ? item.time_from.format("HH:mm") : "-")+" ")]}},{key:"item.time_to",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.time_to ? item.time_to.format("HH:mm") : "-")+" ")]}},{key:"item.weekdays",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.weekdays(item).join(", "))+" ")]}}])}),_c('v-dialog',{attrs:{"max-width":"600"},model:{value:(_vm.editTimetableRecordDialog),callback:function ($$v) {_vm.editTimetableRecordDialog=$$v},expression:"editTimetableRecordDialog"}},[(_vm.timetableRecordToEdit !== null)?_c('edit-timetable-record-form',{attrs:{"timetable-record":_vm.timetableRecordToEdit,"school-year":_vm.schoolYear,"school-class":null},on:{"onSubmit":_vm.onSubmit,"onDelete":_vm.onDelete,"onClose":_vm.onClose}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }