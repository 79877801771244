












import { SchoolClassResponse } from "@/api/school-class/types/Responses";
import { SchoolYearResponse } from "@/api/school-year/types/Responses";
import TimetableRecordClient from "@/api/timetable/TimetableRecordClient";
import {
  CreateHolidayTimetableRecordRequest,
  CreatePossibilityToBringChildTimetableRecordRequest,
  CreatePossibilityToPickUpChildTimetableRecordRequest,
  CreateTimetableRecordRequest,
} from "@/api/timetable/types/Requests";
import { AbstractTimetableRecordResponse } from "@/api/timetable/types/Responses";
import TimetableRecordForm from "@/components/timetable/form/TimetableRecordForm.vue";
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "CreateTimetableRecordForm",
  components: { TimetableRecordForm },
  props: {
    schoolYear: {
      type: null as unknown as PropType<SchoolYearResponse | null>,
      required: true,
    },
    schoolClass: {
      type: null as unknown as PropType<SchoolClassResponse | null>,
      required: true,
    },
  },
  data: () => ({
    // loading
    isBusySubmit: false,
  }),
  methods: {
    onSubmitPossibilityToBringChildTimetableRecord(request: CreatePossibilityToBringChildTimetableRecordRequest): void {
      this.onSubmit({ possibility_to_bring_child_timetable_record: request });
    },
    onSubmitPossibilityToPickUpChildTimetableRecord(request: CreatePossibilityToPickUpChildTimetableRecordRequest): void {
      this.onSubmit({ possibility_to_pick_up_child_timetable_record: request });
    },
    onSubmitHolidayTimetableRecord(request: CreateHolidayTimetableRecordRequest): void {
      this.onSubmit({ holiday_timetable_record: request });
    },
    onSubmit(request: CreateTimetableRecordRequest): void {
      this.isBusySubmit = true;
      TimetableRecordClient.createTimetableRecord(request)
        .then((response: AbstractTimetableRecordResponse) => {
          this.$snackbarSuccess(this.$t("timetables.timetable_record_created"));
          this.$emit("onSubmit", response);
        })
        .finally(() => {
          this.isBusySubmit = false;
        });
    },
    onClose(): void {
      this.$emit("onClose");
    },
  },
});
