











































import AbstractClient from "@/api/AbstractClient";
import { SchoolClassResponse } from "@/api/school-class/types/Responses";
import TimetableRecordClient from "@/api/timetable/TimetableRecordClient";
import {
  AbstractTimetableRecordResponse,
  PossibilityToBringChildTimetableRecordResponse,
  PossibilityToPickUpChildTimetableRecordResponse,
} from "@/api/timetable/types/Responses";
import DataTable, { DataTableAction, DataTableHeader } from "@/components/data-table/DataTable.vue";
import { DataTableFilterType } from "@/components/data-table/DataTableFilter.vue";
import EditTimetableRecordForm from "@/components/timetable/form/EditTimetableRecordForm.vue";
import TimetableRecordType from "@/enums/TimetableRecordType";
import _ from "lodash";
import Vue, { PropType } from "vue";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
  name: "TimetableRecordsBySchoolClassTable",
  components: { EditTimetableRecordForm, DataTable },
  props: {
    schoolClass: {
      type: Object as PropType<SchoolClassResponse>,
      required: true,
    },
    withInherited: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    // dialog
    editTimetableRecordDialog: false as boolean,
    // timetable record detail
    timetableRecordToEdit: null as AbstractTimetableRecordResponse | null,
  }),
  watch: {
    withInherited(): void {
      this.$nextTick(() => {
        (this.$refs["table"] as any).refresh();
      });
    },
  },
  computed: {
    client(): AbstractClient {
      return new TimetableRecordClient();
    },
    clientFunction(): any {
      return TimetableRecordClient.getTimetableRecordsBySchoolClass;
    },
    clientFunctionParameters(): any[] {
      return [this.schoolClass.id];
    },
    clientQueryParameters(): any {
      return {
        withInherited: this.withInherited ? "true" : "false",
      };
    },
    headers(): DataTableHeader[] {
      return [
        {
          text: _.toString(this.$t("timetables.name")),
          value: "name",
          sortable: false,
          filter: {
            key: "type",
            type: DataTableFilterType.SELECT,
            options: [
              {
                text: this.$t("timetables.possibility_to_bring_child"),
                value: TimetableRecordType.POSSIBILITY_TO_BRING_CHILD_TIMETABLE_RECORD,
              },
              {
                text: this.$t("timetables.possibility_to_pick_up_child"),
                value: TimetableRecordType.POSSIBILITY_TO_PICK_UP_CHILD_TIMETABLE_RECORD,
              },
              {
                text: this.$t("timetables.holiday"),
                value: TimetableRecordType.HOLIDAY_TIMETABLE_RECORD,
              },
            ],
          },
          export: {
            json_paths: ["$.items[*].name"],
          },
        },
        {
          text: _.toString(this.$t("timetables.date_from")),
          value: "date_from",
          filter: {
            key: "date_from",
            type: DataTableFilterType.DATE_RANGE,
          },
          export: {
            json_paths: ["$.items[*].date_from"],
          },
        },
        {
          text: _.toString(this.$t("timetables.date_to")),
          value: "date_to",
          filter: {
            key: "date_to",
            type: DataTableFilterType.DATE_RANGE,
          },
          export: {
            json_paths: ["$.items[*].date_to"],
          },
        },
        {
          text: _.toString(this.$t("timetables.time_from")),
          value: "time_from",
          sortable: false,
          export: {
            json_paths: ["$.items[*].time_from"],
          },
        },
        {
          text: _.toString(this.$t("timetables.time_to")),
          value: "time_to",
          sortable: false,
          export: {
            json_paths: ["$.items[*].time_to"],
          },
        },
        {
          text: _.toString(this.$t("timetables.weekdays")),
          value: "weekdays",
          sortable: false,
          export: {
            json_paths: [
              "$.items[*].repeat_on_monday",
              "$.items[*].repeat_on_tuesday",
              "$.items[*].repeat_on_wednesday",
              "$.items[*].repeat_on_thursday",
              "$.items[*].repeat_on_friday",
              "$.items[*].repeat_on_saturday",
              "$.items[*].repeat_on_sunday",
            ],
            delimiter: ", ",
          },
        },
      ];
    },
    actions(): DataTableAction[] {
      return [
        {
          name: "edit",
          icon: "mdi-pencil",
          default: true,
          onClick: (timetableRecord: AbstractTimetableRecordResponse): void => {
            this.timetableRecordToEdit = timetableRecord;
            this.editTimetableRecordDialog = true;
          },
          disabled: (timetableRecord: AbstractTimetableRecordResponse): boolean => timetableRecord.school_year_id !== null,
        },
      ];
    },
  },
  methods: {
    weekdays(timetableRecord: AbstractTimetableRecordResponse): TranslateResult[] {
      // timetable records with possibility to define weekdays
      if (timetableRecord instanceof PossibilityToBringChildTimetableRecordResponse || timetableRecord instanceof PossibilityToPickUpChildTimetableRecordResponse) {
        // all weekdays
        if (timetableRecord.repeat_on_monday && timetableRecord.repeat_on_tuesday && timetableRecord.repeat_on_wednesday && timetableRecord.repeat_on_thursday && timetableRecord.repeat_on_friday && timetableRecord.repeat_on_saturday && timetableRecord.repeat_on_sunday) {
          return [this.$t("timetables.all_weekdays")];
        }
        // all workdays
        if (timetableRecord.repeat_on_monday && timetableRecord.repeat_on_tuesday && timetableRecord.repeat_on_wednesday && timetableRecord.repeat_on_thursday && timetableRecord.repeat_on_friday) {
          return [this.$t("timetables.all_workdays")];
        }
        // enumerate weekdays
        const weekdays: TranslateResult[] = [];
        if (timetableRecord.repeat_on_monday) weekdays.push(this.$t("timetables.mon"));
        if (timetableRecord.repeat_on_tuesday) weekdays.push(this.$t("timetables.tue"));
        if (timetableRecord.repeat_on_wednesday) weekdays.push(this.$t("timetables.wed"));
        if (timetableRecord.repeat_on_thursday) weekdays.push(this.$t("timetables.thu"));
        if (timetableRecord.repeat_on_friday) weekdays.push(this.$t("timetables.fri"));
        if (timetableRecord.repeat_on_saturday) weekdays.push(this.$t("timetables.sat"));
        if (timetableRecord.repeat_on_sunday) weekdays.push(this.$t("timetables.sun"));
        return weekdays;
      }
      // whole-week timetable records
      return [this.$t("timetables.all_weekdays")];
    },
    itemRowClass(timetableRecord: AbstractTimetableRecordResponse): string {
      return timetableRecord.school_year_id !== null ? "disabled" : "";
    },
    onSubmit(): void {
      this.onClose();
      this.refresh();
    },
    onDelete(): void {
      this.onClose();
      this.refresh();
    },
    onClose(): void {
      this.editTimetableRecordDialog = false;
      this.timetableRecordToEdit = null;
    },
    refresh(): void {
      (this.$refs["table"] as any).refresh();
    },
  },
});
