

































































































import HelpMessage from "@/components/layout/HelpMessage.vue";
import CreateTimetableRecordForm from "@/components/timetable/form/CreateTimetableRecordForm.vue";
import Vue from "vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import { SchoolYearResponse } from "@/api/school-year/types/Responses";
import { SchoolClassResponse } from "@/api/school-class/types/Responses";
import TimetableRecordsBySchoolClassTable from "@/components/timetable/table/TimetableRecordsBySchoolClassTable.vue";
import TimetableRecordsBySchoolYearTable from "@/components/timetable/table/TimetableRecordsBySchoolYearTable.vue";
import PageSubtitle from "@/components/layout/PageSubtitle.vue";

export default Vue.extend({
  name: "TimetableRecords",
  components: {
    HelpMessage,
    CreateTimetableRecordForm,
    PageSubtitle,
    TimetableRecordsBySchoolYearTable,
    TimetableRecordsBySchoolClassTable,
    DefaultLayout,
  },
  data: () => ({
    // with inherited flag
    withInherited: false,
    // dialogs
    createTimetableRecordBySchoolYearDialog: false,
    createTimetableRecordBySchoolClassDialog: false,
  }),
  computed: {
    selectedSchoolClassAsTeacher(): SchoolClassResponse | null {
      return this.$store.getters["Setting/getSelectedSchoolClass"];
    },
    selectedSchoolYearAsTeacher(): SchoolYearResponse | null {
      return this.$store.getters["Setting/getSelectedSchoolYear"];
    },
  },
  methods: {
    onSubmitTimetableRecord(): void {
      (this.$refs["timetable-records-by-school-year-table"] as any).refresh();
      (this.$refs["timetable-records-by-school-class-table"] as any).refresh();
      this.createTimetableRecordBySchoolYearDialog = false;
      this.createTimetableRecordBySchoolClassDialog = false;
    },
  },
});
